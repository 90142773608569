<template>
  <base-section id="local-disabled" space="0">
    <v-container class="fill-height px-4 pt-16">
      <v-row>
        <v-col cols="12">
          <base-heading
            class="primary--text"
            title="Local desactivado. Por favor, contacte con soporte."
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "LocalDisabledSection",

  provide: {
    heading: { align: "center" },
  },
};
</script>
